<div class="header global-wrapper" *tuiLet="{
  settingsLoaded: userSettingLoad$ | async,
  customLogoEnabled: customLogoEnabled$ | async
} as data">
  <div class="header__logo">
    <a class="header__logo-image"
       routerLink="/"
       (click)="amplitudeEventTracking('Logo Click')">
      <img
        *ngIf="!data.customLogoEnabled"
        [src]="linkLogo$ | async"
        alt="logo"/>
      <img
        *ngIf="data.customLogoEnabled"
        class="header__logo-client"
        [src]="clientCustomLogoUrl$ | async"
        alt="client logo"/>
    </a>
  </div>

  <ul class="header__menu">
    <li *ngIf="dashboardFeature | async">
      <span
        class="header__menu-wrapper"
        routerLinkActive="header__menu-wrapper_active"
      >
        <a class="header__menu-item" routerLink="/dashboard" i18n
           (click)="amplitudeEventTracking('Top Menu - Dashboard Click')"
        >
          Dashboard
        </a>
      </span>
    </li>
    <li *ngIf="hasProAccess | async">
      <span
        class="header__menu-wrapper"
        routerLinkActive="header__menu-wrapper_active"
      >
        <a class="header__menu-item" routerLink="/account-discovery" i18n
           (click)="amplitudeEventTracking('Top Menu - Accounts Click')"
        >
          Accounts
        </a>
        <a
          routerLink="/private-account-discovery"
          class="header__menu-item header__menu-item_hidden"
        ></a>
      </span>
    </li>
    <li *ngIf="hasProAccess | async">
      <a
        class="header__menu-item"
        routerLink="/industry-research"
        routerLinkActive="header__menu-item_active"
        i18n
        (click)="amplitudeEventTracking('Top Menu - Industries Click')"
      >
        Industries
      </a>
    </li>
    <li *ngIf="(hasProAccess | async) && prospectingFeatureEnabled">
      <a
        class="header__menu-item"
        [routerLink]="'/journeys/' + JourneyType.ResearchProspects"
        routerLinkActive="header__menu-item_active"
        (click)="pageView(TrackingCategory.Prospecting); amplitudeEventTracking('Top Menu - Prospecting Click');"
        i18n
      >
        Prospecting
      </a>
    </li>
    <li>
      <app-learn-basics
        class="header__menu-item header__menu-item-button"
        [isActive]="onLearningRoute"
      ></app-learn-basics>
    </li>
  </ul>
  <!-- <div class="header__recently-viewed" #recentlyContainer>
    <app-list-button
      #recentlyTooltip="matTooltip"
      matTooltip="Recently Viewed"
      matTooltipClass="mat-tooltip-closure"
      i18n-matTooltip
      [isRecently]="true"
      (click)="openRecentlyPopover(recentlyContainer)"
    ></app-list-button>
  </div> -->
  <div class="header__favorites" #favoritesContainer>
    <app-list-button
      #favoritesTooltip="matTooltip"
      matTooltip="Favorites"
      matTooltipClass="mat-tooltip-closure"
      i18n-matTooltip
      [isFavorites]="true"
      (click)="openFavoritesPopover(favoritesContainer)"
    ></app-list-button>
  </div>
  <div class="header__profile">
    <app-profile-menu class="header__profile-control"></app-profile-menu>
  </div>
</div>
