<app-profile-button
  #handle
  class="profile-popover__heading"
  [isOpened]="true"
  [user]="user"
  (click)="closePopover()"
></app-profile-button>
<div
  #content
  class="profile-popover__content"
  [class.profile-popover__content_full]="isFullWidth"
  [ngStyle]="{
    width: isFullWidth || containerWidth !== 0 ? containerWidth + 'px' : 'auto'
  }"
>
  <nav class="profile-popover__actions">
    <div *ngIf="hasProAccess && customCompaniesEnabled" class="profile-popover__action">
      <a
        class="profile-popover__action-link"
        routerLink="/added-companies"
        i18n
        (click)="trackLinkClick('Extended Menu - Custom Companies Click'); closePopover()"
      >
        <mat-icon
          class="profile-popover__action-icon profile-popover__action-add"
          svgIcon="custom"
        ></mat-icon>
        <span>Custom Companies</span>
      </a>
    </div>
    <div *ngIf="pursuitVisible$ | async" class="profile-popover__action">
      <a
        class="profile-popover__action-link"
        routerLink="/profile/pursuits"
        i18n
        (click)="trackLinkClick('Extended Menu - Pursuits Click'); closePopover()"
      >
        <mat-icon
          class="profile-popover__action-icon"
          svgIcon="description"
        ></mat-icon>
        <span>Pursuits</span>
      </a>
    </div>
    <div *ngIf="(hasProAccess || isClientAdmin)" class="profile-popover__action">
      <a
        class="profile-popover__action-link"
        routerLink="/value-modeler"
        i18n
        (click)="trackLinkClick('Extended Menu - Value Modeler Click'); closePopover()"
      >
        <mat-icon
            class="fnl-text-blue profile-popover__action-icon"
            svgIcon="value-modeler"
        ></mat-icon>
        <span>Value Modeler</span>
      </a>
    </div>
    <div *ngIf="isClientAdmin" class="profile-popover__action">
      <a
        class="profile-popover__action-link"
        routerLink="/usage-reports"
        i18n
        (click)="trackLinkClick('Extended Menu - Usage Reports Click'); closePopover()"
      >
        <mat-icon
          class="profile-popover__action-icon"
          svgIcon="report"
        ></mat-icon>
        <span>Usage Reports</span>
      </a>
    </div>
    <div *ngIf="isClientAdmin" class="profile-popover__action">
      <a
        class="profile-popover__action-link"
        routerLink="/administration"
        i18n
        (click)="trackLinkClick('Extended Menu - Administration Click'); closePopover()"
      >
        <mat-icon
          class="profile-popover__action-icon"
          svgIcon="settings"
        ></mat-icon>
        <span>Administration</span>
      </a>
    </div>
    <div class="profile-popover__action">
      <a
        class="profile-popover__action-link"
        [href]="resetPasswordLink"
        target="_blank"
        rel="noopener noreferrer"
        i18n
        (click)="trackLinkClick('Extended Menu - Password Reset Click'); closePopover()"
      >
        <mat-icon
          class="profile-popover__action-icon profile-popover__action-icon_modified"
          svgIcon="round-person"
        ></mat-icon>

        <span
          >Password Reset
          <mat-icon
            class="profile-popover__action-icon_external stroke-fill"
            svgIcon="external-link"
          ></mat-icon>
        </span>
      </a>
    </div>
    <div class="profile-popover__action">
      <button class="profile-popover__action-button" i18n (click)="trackLinkClick('Extended Menu - Logout Click'); logOut()">
        <mat-icon
          class="profile-popover__action-icon"
          svgIcon="logout"
        ></mat-icon>
        <span>Log Out</span>
      </button>
    </div>
  </nav>
</div>
